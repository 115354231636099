/* homepage css */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: 0;
  
    font: inherit;
    vertical-align: baseline;
    outline: var(--none);
    box-shadow: var(--none);
    font-size: inherit;
  }
  
.blog-home-page{
    width: 100%;
    /* display: flex; */
    flex-direction: column;
    }
    
    @media only screen and (min-width : 1024px) {
      .blog-home-page{
        flex-direction: row;
      }
    }