.about.inner-content #wpa_content p{
    font-style: italic;
    margin-bottom: 20px;
}
.about.inner-content #wpa_content p strong{
    font-weight: 700;
}
.about.inner-content article{
    width: 80%;
}
h2, .h2 {
    font-size: 30px;
}
.about .topbar .breadcrumb li span {
    text-transform: initial;
}



@media screen and (max-width: 1023px){

    .about .post-detail h2 {
        font-size: 20px;
        margin-bottom: 3rem;
        margin-top: 0;
    }
    .about .mobile-Blogger {
        margin-top: 101px;
    }
    
    
}