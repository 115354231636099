.cookie_block {
    display: flex;
    justify-content: space-between;
    width: 57%;
    margin: 0 auto;
}

.cookie_block .cookie_text p{
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.setting_popup h3 {
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    margin-bottom: 1rem;
}

.setting_popup p {
    font-weight: 500;
    font-size: 16px;
}

.setting_popup .popup_text label {
    font-size: 16px;
}

.setting_popup .popup_text input {
    margin-right: 10px;
}

@media screen and (min-width: 1800px) {
    .cookie_block {
        width: 40%;
    }
}

@media screen and (max-width: 1024px) {
    .cookie_block {
        flex-direction: column;
        width: 100%;
    }
    .cookie_block .accept_button {
        width: fit-content;
    }
    .cookie_block .cookie_text {
        margin-bottom: 1rem;
    }
}