/* ScrollToTop.css */
.scroll-to-top {
    position: relative;
    /* bottom: 20px;
    right: 20px; */
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
  }
  
  .scroll-to-top.show {
    opacity: 1;
    visibility: visible;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .scroll-to-top-button {
    /* background:url(https://www.arunnathaniblog.com/wp-content/themes/Arun_Blog/images/scrolltop.png); */
/*
   display: block;-webkit-user-select: none;margin: auto;background-color: hsl(0, 0%, 90%);transition: background-color 300ms; 
     color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    
    /* width: 35px;
    height: 35px;
    transition: background-color 0.3s, transform 0.3s;
    padding: 0px; */
    /* text-align: center;
    color: #444;
    text-decoration: none; */
    position: fixed;
    bottom: 35px;
    right: 15px;
    background: transparent;
  }
  .scroll-to-top-button img{
width: 35px;
height:35px;;
  }
  
  
  .scroll-to-top-button:active {
    transform: scale(0.95);
  }
  