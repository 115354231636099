.tags a:after, .categories a:after {
  content: ',';
}
.tags a:last-child:after, .categories a:last-child:after{
  content: '';
}
.comment-area  .input-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.post-detail .input-wrapper>div {
  width: 100%;
  display: flex;
}
.comment-area .comments-heading {
  display: flex;
  align-items: center;
}
#comments ol.comment-list ul.comment-replies {
  margin-left: 3rem;
  margin-top: 2rem;
}
.post-detail p span#email-notes,.post-detail p span.required-field-message{
  text-align: left;
}
#comments ol.comment-list .comment-replies li {
  display: unset;
  margin-bottom: 0;
}
.post-detail .input-wrapper input,
.post-detail textarea#comment{
  display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    outline: 0;
    /* margin-bottom: 2rem; */
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.post-detail form button[type="submit"] {
  background-color: #1f86af;
  color: #ffffff;
  font-weight: 600;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.comment-area .comments{
  display: flex;
}
.comment-area .comments button {
  color: #989898;
  display: inline-block;
  margin: 25px 20px;
  text-transform: capitalize;
  border: none;
  background: #fff;
}
.comment-area ul li {
  list-style: none;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
}
.comment-area ul.comment-list{
  padding-left: 0;
}
.comment-area .comment-body {
  /* padding-left: 3rem; */
  font-size: 14px;
  width: 65%;
}
.comment-area .comment-author {
  width: 10%;
}
.comment-area .mandatory{
  color: #e7482e;
  line-height: 0px;
}
.comment-area .comment-notes{
  margin-bottom: 2rem;
}
.comment-area .leave-comment{
  font-size: 30px;
  font-weight: 500;
}
.comment-area .comment-list img{
  border-radius: 50%;
  box-shadow: 1px 3px 1px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 1px 3px 1px 0px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 1px 3px 1px 0px rgba(0, 0, 0, 0.51);
}
.sub-input-wrapper span.error,.comment-textarea span.error {
  color: #a94442 !important;
}
.comment-area .comment-textarea {
  display: flex;
  /* margin-top: -0.5rem; */
  flex-direction: column;
  margin-bottom: 2rem;
}
.input-wrapper.comment-form{
  display: flex;
  justify-content: space-between;
}
.post-detail textarea#comment {
  height: 54px;
}
.comment-body .reply .comment-edit-link{
  display: none;
}
.comment-form-title {
  margin-bottom: 8px;
}
.comment-form-title h2 {
  display: inline;
  color: #515151;
  font-size: 24px;
}
.comment-form-title h2 span{
  color: #428bca;
}
.comment-form-title h2 span:hover,
.comment-form-title small a:hover{
  text-decoration: underline;
}

@media screen and (min-width: 1023px){
  .post-detail .input-wrapper div.sub-input-wrapper {
    width: 46%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .input-wrapper .form-control-wrapper,.comment-textarea .form-control-wrapper{
    display: flex;
    flex-direction: row;
  }
  .comment-area  .input-wrapper{
    flex-wrap: unset;
  }
}

