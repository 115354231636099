.privacy-page #wpa_content {
    width:70%;
    }
     
    .privacy-page #wpa_content ul{
    padding-left: 40px;
    margin-bottom: 10px;
    }
    @media screen and (max-width: 1024px) {
    .privacy-page #wpa_content{
        width: 100%;
    }
    .privacy-page-content .mobile-Blogger {
        margin-top: 101px;
    }
}