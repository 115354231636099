/*detail blog css starts*/
.content{
  margin-left: 0;
}
.pdf-download {
  display: none;
}

.wp-polls ul, .wp-polls-ul, .wp-polls-ans ul {
  text-align: left;
  list-style: none;
  float: none;
}
.wp-polls-ul {
  padding: 0px;
  margin: 0px 0px 10px 10px;
}
.wp-polls-ul li label{
  font-weight: 550;
    font-style: normal;
}
.dashicons:before {
  content: "\f012";
  font-family: fontawesome;
  margin-right: 5px;
}
.inner-content {
    padding: 30px;
    background-color: #ebf0f1;  
  }
  .post-detail {
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.15);
    -web-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 40px;
    /* margin-top: 50px; */
  }
   strong {
    font-weight: 700;
}
.privacy-page .wpa_content{
  width: 75%;
}
  .post-detail article h2{
    font-family: inherit;
    font-size: 36px;
  font-weight: 400;
  }
  .post-detail .blog-detail-data{
    width: 75%;
  }
  .post-detail p {
    margin: 0 0 1.4em 0;
    line-height: 1.65;
    text-align: justify;
    color: #4D4D4D;   
    word-break: break-word; 
}
.post-detail img {
  max-width: 500px;
  height: auto;
  
}
em {
  font-style: italic;
}
.aligncenter {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.wp-block-image figcaption {
  margin-bottom: 1em;
  margin-top: .5em;
  font-size: 12px;
}
  .entry-standard.entry-cat span a,.entry-standard.entry-style span a,.entry-standard.entry-cat  a,.entry-standard.entry-style  a,
  .main-category-sub a, .entry-date.pull-left span a{
    color: #a7a7a7 !important;
  }
  .post-detail article .entry-meta {
    display: flex;    
    flex-wrap: wrap;
    padding: 1rem 0;
    align-items: center;
  }
   
  .post-detail article .entry-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .post-detail article .entry-meta>div {
    margin-right: 2rem;
  }
  .post-detail .line {
    margin-bottom: 30px;
  }
  .entry-info .pdf-download a {
    color: #a7a7a7;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .entry-info .pdf-download img{
    padding-right: 10px;
  }
  .tags {
    margin-top: 23px;
  }
  .tags a, .categories a {
    text-decoration: none;
    color: #0f9fb4;
    font-size: 12px;
    text-transform: capitalize;
  }
  .tags a:hover,.categories a:focus,
  .categories a:hover, .tags a:focus {
    color: #2a6496;
    text-decoration: underline;
}
.mobile-BloggerName {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
}
  .prev-next-wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    padding: 20px 0;
    margin: 20px 0;
  }
  .fa-angle-right:before{
    content: "\f105";
  }
  .prev-next-wrapper .fa-solid {
    color: #e5e5e5;
    font-size: 50px;
   
}
.prev-next-wrapper a{
display: flex;
align-items: center;
margin: 0 5px;
}
.wp-block-image figure img{
  width: 100%;
  /* max-width: 100%; */
  height: auto;
}
.selectgrp {
  border: 1px solid #bdc3c4;
  border-radius: 4px;
  display: block;
  margin-bottom: 6px;
  margin-right: 7px;
}
.selectgrp .select-list {
  position: relative;
  background-color: #FFF;
  margin-bottom: 0px;
  border-radius: 4px;
}
.selectgrp label {
  font-weight: 500;
  display: inline-block;
    max-width: 100%;
}
.selectgrp .select-list span {
  color: #a1a1a1;
  padding: 0 0 0 10px;
  font-size: 13px;
}
#group-list {
  padding: 5px 10px 5px 0;
  margin: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: transparent;
  color: #5e5e5e;
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  width: 95px;
  font-size: 13px;
}
.filetr-ondetail{
  display: flex;
}
.blogs-text-content ol{
  padding-left: 40px;
  margin-bottom: 10px;
}
.recaptcha-wrapper span.error {
  color: #a94442 !important;
}
.selectgrp label:before {
  background: none repeat scroll 0 0 #FFF;
  border-radius: 4px;
  content: "";
  display: block;
  height: 30px;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0px;
  /* width: 10px; */
}
.selectgrp label:after {
  color: #aaa;
  content: ">";
  font: 16px "Consolas", monospace;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 5px;
  transform: rotate(90deg);
}
.inner-content .post-detail .wp-caption {
  width: auto !important;
}
 
.inner-content .post-detail .wp-caption-text {
    margin-bottom: 0.8rem;
    font-size: 12px;
}
.post-detail .categories label,.post-detail .tags label{
  font-weight: 600;
}
.inner-content .post-detail .categories {
  margin-top: 5px;
}
.post-detail .comment-area img{
width:revert-layer;
}
.comment-list li article{
  display: flex;
}
.center{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
.alignleft {
   float: left;
   margin-right: 15px;
}
img[alt="happy"] {
  width: 20px !important;
  height: 20px;
  vertical-align: middle;
}
.prev-next-wrapper .prev{
  margin-left: 10px;
}
.prev-next-wrapper .next{
  margin-right: 10px;
}
#comments ol.comment-list{
  margin-top: 1rem;
}
.inner-content .post-detail .menu-social-links-menu-container li {
  margin-right: 10px;
}
#mediaplayer_wrapper ,.mediaplayer-wrapper{
 
  height: 315px !important;
  width: 500px !important;
}
.inner-content .post-detail article h2 {
  margin-bottom: 0;
}

.inner-content .post-detail article .entry-meta {
  padding: 0;
}
.inner-content .post-detail .wp-polls-loading {
  display: none;
}
.wp-polls .Buttons {
  border: 1px solid #c8c8c8;
  background-color: #f3f6f8;
}
.toggle-read{

margin-bottom: 2rem;
}
.toggle-read .read-content{
color:#a7a7a7;
}
.toggle-read .read-arrow{
   color: #2a6496;
text-decoration: underline;
}
.recaptcha-wrapper{
  margin-bottom: 2rem;
}
/* @keyframes spreadFromTopLeft {
  0% {
    opacity: 0;
    transform: scale(0) translate(0%, -50%);
  }
 
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}
@keyframes spreadFromTopLeft-form {
  0% {
    opacity: 0;
    transform: scale(0) translate(0%, -50%);
  }
 
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
} */


@media screen and (max-width: 1024px) {
   header .navbar-default .navbar-toggle.active{
    background-color: #1d2f3e;
  }
   /* header .navbar-default .navbar-toggle:hover, header .navbar-default .navbar-toggle:focus, 
  header .navbar-default .navbar-toggle:active,header .navbar-default .navbar-toggle.active {
    background-color: #1d2f3e;
} */
/* .navbar-toggle {
  background-color: transparent;
  padding: 13px;
  margin: 15px 0 0;
} */
.navbar-toggle {
  background-color: transparent;
  padding: 10px;
  margin-left: 0;
  margin-top: 10px;
}
  .prev-next-wrapper {
    padding: 5px 0;
  }
  .prev-next-wrapper div.prev, 
  .prev-next-wrapper div.right {
    display: contents;
  }  
  .prev-next-wrapper span.next,
  .prev-next-wrapper span.prev {
      text-overflow: ellipsis;
      width: 80px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
  }
  .prev-next-wrapper .fa-solid {
    font-size: 35px;
  }
  .prev-next-wrapper .next {
    margin: 0;
  }
  .prev-next-wrapper .prev {
    margin-left: 5px;
  }
  .prev-next-wrapper a {
    margin: 0;
  }

  
}

@media screen and (min-width: 1023px){
  .inner-content .post-detail .custom-like {
    margin-left: 8rem;
}
 

 
  .blogs-text-content,
  .about #wpa_content{
    max-width: 610px;
  }
  .inner-content{
    margin-top: 110px;
  }
  .content {
    margin-left: 290px;
}
.post-detail .detail-content{
  width: 75%;
}
}
@media screen and (max-width: 1023px){
  .blog-category .post-container .mobile-Blogger {
    margin-top: 101px;
  }
  .recaptcha-wrapper {
    -webkit-transform: scale(0.92);
    -webkit-transform-origin: 0 0;
  }
  .subcategory-list{
  overflow: scroll;
  max-height: 40vh;
  }
  .rel_iframe iframe{ width:100%}
  .post-detail #mediaplayer_wrapper,
  .post-detail .mediaplayer-wrapper,
  .post-detail .wp-video,
  .post-detail .wp-video-shortcode,
  .post-detail .wp-video video {
    width: 100% !important;
    height: unset !important;
}
.post-detail #mediaplayer_wrapper video,
.post-detail .mediaplayer-wrapper iframe
.post-detail .wp-video {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
}
 
.post-detail article .entry-info {
  justify-content: start;
}
 
.inner-content .post-detail article .entry-meta {
    padding: 1.2rem 0;
}
 
.inner-content .entry-meta .entry-date, 
.inner-content .entry-meta .entry-standard {
    margin-bottom: 10px;
}
 
.entry-info .pdf-download a {
    flex-direction: column;
}
 
.entry-info .pdf-download img {
    width: auto;
}
  .arun-mobile-imgblk{
    width: 35%;
  }
  .ceo-text{
    width: 65%;
  }
  .inner-content .post-detail .custom-like {
    margin-left: 5rem;
}
  .sub-input-wrapper{
  flex-direction: column;
    margin-bottom: 2rem;
  }
  .form-control-wrapper{
    display: flex;
  }
  .post-detail img {
    width: 100%;
    }
  .inner-content {
    padding: 20px;
    
  }
  
  .post-detail {
    padding: 20px;
  }
 
  .comment-area .comment-author {
    width: 35% !important;
  }
 
  .comment-area .comment-body {
    width: 65%;
  }

  .sidebar{
    display: none;
  }
  .post-detail article h2 {
    font-family: inherit;
    font-size: 25px;
    font-weight: 500;
  }
  .post-detail article .entry-meta>div {
    margin-right: 1rem;
  }
  .detail-content{
   font-size: 13px;
  }
  .topbar .col-xs-offset-1 {
    margin-left: 20px;
  }
}
  /*detail blog css ends*/
  /* mobile arun image css */
.mobile-Blogger{
    display: flex;
}

.mobile-BloggerName {
    font-size: 15px;
    text-align: left;
}
.mobile-BloggerPost {
    font-size: 12px;
}
.reset-button{						
	background-color: #333333;
    color: #ffff;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
}	
.prev-next-wrapper .prev, .prev-next-wrapper .right	
{	
display: flex;
    align-items: center;
    color: #8f8f8f;
}
.inner-content .parts {
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}
.inner-content .parts label {
  color: #d2625f;
  font-size: 12px;
  font-weight: normal;
}
.inner-content .parts a {
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 500;
}
.inner-content .parts a {
  margin-left: 4px;
}

.inner-content .version {
  display: flex;
  margin-top: 5px;
  gap: 5px;
}
.inner-content .version a {
  color: black;
  font-size: 16px;
}