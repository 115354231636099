.main-container {
    background-color: #ebf0f1;
    margin-left: 290px;
    height: auto;
    /* overflow: auto; */
    position: relative;
    
}

body {
    color: #1d1d1b;
    font-size: 14px;
    font-family: Segoe UI, fontawesome !important;
    background: #BABABB !important;
  }
  h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p {
    margin: 0 0 10px;
  }
  a,a:hover{
    text-decoration: none;
    color: #428bca;
  }
  .post-container #masonry .row {
    display: flex;
    gap: 15px;    
    flex-wrap: wrap;
  }
  .post-container #masonry {
    padding: 1.1rem;
  }
  #masonry .post.isotope-item {
    width: 25%;
    background-color: #fff;
    /* padding: 12px 25px !important; */
    /* margin-bottom: 3rem; */
  }
  .pager .dots{
    margin-right: 5px;
  }
  .post .title a {
    color: #5a5454;
     /* font-family: SegoeUI-SemiBold;  */
    font-size: 22px;
    font-weight: 500;
    text-decoration: none;
  }
  .entry-info label {
    color: #d2625f;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
    margin-right: 5px;
  }
  .post p.post-description {
    color: #585555;
    margin-top: 1rem;
    height: 45px;
    overflow: hidden;
  }
  .action-links{
    text-align: left;
  }
  .read-more a,.read-more button {
    background-color: #b9c7d5;
    color: #ffffff;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 21px;
    text-align: center;
    width: 70%;
  }
  .action-links {
    margin: 25px auto 0;
    width: 250px;
}
.post-no-Img p.post-description  {
    height: 235px;
    overflow: hidden;
    margin-top: 16px;
    width: 100%;

}
.post_content {
  height: 380px;
  /* margin-bottom: 10px; */
  overflow: hidden;
}
.menu-li-soc a img{
    border-radius: 50%;
}
li.menu-li-soc{
    margin-right: 5px;
}
  .read-more a:hover, .read-more button:hover{
    background: #1d2f3e;
    color: #ffffff;
  }
  .sidebar section.col-sm-12 {
    border-top: 1px solid #31424f;
    padding: 30px 20px;
    background-color: #1d2f3e;
}
  .post-container {
    background-color: #ebf0f1;
    /* padding: 20px 10px; */
    margin-bottom: 15px;
}
.post-container .pager {
    margin: 20px 0 30px;
    position: relative !important;
}
.post-container .pager {
    margin: 20px 0 30px !important;
    position: relative !important;
}
b{
    font-weight: 700;
}
header .navbar-brand .txtcolor {
    color: #01405D;
    font-size: 18px;
    margin-left: 15px;
}
ul.nav-list.desktop-nav li {
  margin-left: 10px;
}

 
header .navbar-nav > li > a {
    font-size: 13px;
    padding-top: 20px;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
header .navbar-default .navbar-nav>li>a {
    color: #ffffff;
}
.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none;
}
.post-no-Img, .post-parts {
    padding: 12px 25px !important;
}
.post-with-Img p.post-description p {
  height: 60px;
  overflow: hidden;
  margin-top: 7px !important;
}
.post-with-Img .article {
  padding: 0px 25px;
}
.action-links {
  margin: 25px auto 0;
  width: 250px;
}

.BloggerName {
    color: #ffffff;
    font-size: 20px;
}
.BloggerPost span {
    font-weight: 700;
}
.BloggerPost a {
    color: #AADCD5 !important;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none !important;
}
.header {
  width: 100%;
  box-sizing: border-box;
  max-width: 1281px;
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 1;
  margin-left: -291px;
  padding-left: 290px;
}
.post-container .main-category-page {
  display: flex;
  justify-content: space-between;
}
.post-container form .filetr-ondetail{
  padding-left: 20px;
}
.post-container div.pager,
.post-container .main-category-page form{
    margin-top: 110px !important;
    padding-top:35px;
}

header .navbar {
    background: none repeat scroll 0 0 #0f9fb4;
    border: medium none;
    border-radius: 0;
    height: 63px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-bottom: 0;
}

ul.nav-list{
    display: flex;
    list-style: none;
    align-items: center;
   
}
ul.nav-list li{
    margin-right: 20px;
    
    font-size: 13px;
    text-transform: uppercase;
}
ul.nav-list li a{
    color: #fff;
}
ul.nav-list li.active a{
  color:#64ebff;
}
header #navbarCollapse {
    padding: 0;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #e7e7e7;
}
.navbar-collapse.collapse {
    display: block ;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
}
/* .navbar-toggle {
    display: none;
} */
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}
header .navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-toggle{
  background-color: transparent;
  margin-left: 15px;
}

.collapse.in{
  display: none;
}
header .navbar-brand, header .navbar-nav>li>a {
    text-shadow: none;
}
header .navbar-brand, header .navbar-brand:hover {
    color: #ffffff !important;
    font-family: Segoe UI !important;
    padding: 20px;
}
.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}
header .navbar-brand span:first-child, header .navbar-brand:hover span:first-child{
    font-size: 24px;
}
.navbar-default{
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 5px rgba(0,0,0,.075);
}
.img-responsive{
    display: block;
    
    max-width: 100%;
    height: auto;
}
.menu-arun-blog-menu-container .nav.navbar-nav {
    margin-right: 3px;
    float: right;
}
ul.nav-section-new {
    list-style: none;
    display: flex;
    align-items: center;
    border-top: 1px solid #ebebeb;
}
#masonry .menu-social-links-menu-container{
  margin: 0 25px;
}
.post-detail .menu-social-links-menu-container{
  margin-top: 2.5rem;
}
.custom-like {
    display: flex;
    align-items: center;
    background-color: #0f9fb4;
    padding: 0 0.5rem;
    border-radius: 5px;
   
    margin-left: 10px;
}
.custom-like .like-count {
    margin-left: 5px;
    color: #fff !important;
}
.custom-like .like-count {
    margin-left: 5px;
    color: #fff !important;
}

.custom-like a .fa-thumbs-up:before {
    font-size: 1.3rem;
}
.pull-right {
    float: right !important;
}
.comment-cnt {
    color: #8e8e8e !important;
    font-size: 14px;
    vertical-align: middle;
    line-height: 50px;
    width: 100px;
    
    text-align: right;
}
.row .empty-records{
  font-size: 25px;
  margin: 25px 50px;
}
.post-share a, .post-share span {
    color: #cbcbcb;
}
.comment-cnt .fa.fa-comment {
    margin: 13px 0;
    font-size: 20px;
    border-radius: 12px;
  
    margin: 16px 0;
    padding: 4px;
    margin-right: 5px;
    text-decoration: none;
    text-align: center;
    vertical-align: bottom;
    height: 22px;
    width: 22px;
    color: #cbcbcb;
}
.comment-cnt a:hover .fa.fa-comment,
.comment-cnt a:active .fa.fa-comment{
  text-decoration: unset;
}
.comment-cnt .fa-comment {
    font-size: 20px;
}
.topbar {
    background-color: #FFF;
    -webkit-box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
    height: 50px;
}
.topbar .breadcrumb {
    background-color: #ffffff;
    border-radius: 0;
    margin-bottom: 0;
    padding: 15px 28px !important;
    list-style: none;
}
.navbar-nav>li {
    float: left;
    position: relative;
    display: block;

}
.topbar ul {
    padding: 0;
    display: flex;
    list-style: none;
}
.topbar.filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.st_facebook_large .stButton, .st_twitter_large .stButton, .st_googleplus_large .stButton, .st_linkedin_large .stButton {
  margin-top: -28px;
  margin-left: -6px;
  height: 24px;
  width: 24px;
  text-decoration: none;
  color: #000000;
  display: inline-block;
  cursor: pointer;
}
.topbar ul.category li a.active, .topbar ul.category li a:hover {
  border-bottom: 2px solid red;
  color: #ff4301;
}

.topbar ul.category li a {
  color: #888888;
  display: block;
  font-size: 12px;
  padding: 16px 0;
  text-decoration: none;
}
.topbar ul.category li a span img {
  margin-left: 4px;
  vertical-align: middle;
}
.topbar .col-xs-offset-1 {
  margin-left: 24px;
}
.topbar.filter ul {
  padding: 0;
  margin-right: 15px;
}
.BloggerPost {
    color: #AADCD5;
    font-size: 14px;
    margin-top: 10px;
}
#mosonary{

    position: relative;
    overflow: hidden;
    height: 2635px;
}
.post-container .isotope {
    width: 100%;
}
.post.col-md-3{
    /* position: absolute;
    left: 0px;
    top: 0px; */
    
    width: 300px !important;
    /* height: 507px; */
    overflow: hidden;
    /* width: 31%; */
    
    padding: 0;
}
.post {
    background-color: #ffffff;
    margin-bottom: 20px;
    -webkit-box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.pager button{
  border: 1px solid #bdc3c4;
  border-radius: 4px;
  color: #119db4;
  background: #fff;
      color: #119db4;
  margin-right: 5px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  cursor: pointer;
}
.pager button.current{
    background: transparent;
}
.nav-item.menu-li-soc span.fa,.custom-like .fa{
border-radius: 12px;
    font-size: 15px;
    margin: 16px 0;
    padding: 4px;
    text-decoration: none;
    text-align: center;
    vertical-align: bottom;
    height: 22px;
    width: 22px;
    color:#cbcbcb;
    border: 1px solid #cbcbcb;
}
.nav-item.menu-li-soc .fa-facebook:hover,
.nav-item.menu-li-soc .fa-x-twitter:hover,
.nav-item.menu-li-soc .fa-linkedin:hover{
  color: #5778ae;
  opacity: 0.75;
}
.social-icons .comment-cnt a:hover{
  color: #2a6496;
  text-decoration: underline;
}
.custom-like a i.fa.fa-thumbs-up {
    margin: 0;
    height: unset;
    width: unset;
    border: none;
}
.comment-cnt a{
    color: #cbcbcb;
    /* display: flex;
    align-items: center;
    float: right; */

}
.post-container div.pager.bottom-pager{
    margin-top: 0px !important;
    padding-top: 0;
    margin-bottom: 0.5rem !important;
}

.category-list {
    list-style: none;
    padding: 0;
  }
  body, h1, h2, h3, h4, h5, h6  {
    font-family: "Segoe UI", Arial, sans-serif;
  }
  .topbar .breadcrumb li.search-result-for{
    font-weight: 700;
  }
  .category-name {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .expand-icon {
    margin-left: auto; /* Push the icon to the right */
  }
  
  .subcategory-list {
    list-style: none;
    
  }
  
  .subcategory {
    margin-top: 5px;
  }
  
  /* .category-name.expanded .expand-icon::before {
    content: "-";
  }
  
  .category-name .expand-icon::before {
    content: "+";
  } */
.sidebar {
  /* background-color: #1d2f3e; */
  position: fixed;
  width: 290px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}
  .ceo-text{
    background-color: #409b8f;
    padding: 10px 15px;
    position: relative;
    text-align: center;
    width: 100%;
    color: white;
  }
  #menu {
    margin: 0 -20px;
    padding: 0;

    padding: 0;
}
#menu li {
  list-style: none;
}
#menu li span{
  color: #ababab;
  display: block;
  
  font-size: 14px;
  text-transform: uppercase;
  
    display: block;
    font-weight: 500;
    
    text-decoration: none;
    outline: none;
    /* border-left: 4px solid transparent; */
}
#menu > li > span{
  border-left: 4px solid transparent;
}
.category-name {
  display: flex !important;
}
#menu li .subcategory-list li:hover{
  background-color: #14212d;
    /* font-weight: 700; */
    color: #0f9fb4;
    font-size: 12px;
    text-decoration: none;
}
#search, .input-group-btn .search-icon {
  background-color: #637789;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 10px;
  height: 38px;
}
.prev.page-numbers.disabled{
  display: none;
}
.input-group {
  position: relative;
  display: flex;
  border-collapse: separate;
 
}
#menu li .subcategory-list li{
  font-size: 12px;
  color: #80a2be;
  text-transform: uppercase;
  display: block;
  padding: 5px 0 5px 18px;
}
.sidebar::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
  scrollbar-width: thin;
}

.sidebar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  scrollbar-width: thin;
}
 #menu >li > a:hover,
 #menu >li > span:hover,
  #menu >li.active > a, 
  #menu2 >li > a:hover, 
  #menu2 >li.active > a, 
  #menu >li > a.active ,
  #menu >li > a span active
  {
  color: #0f9fb4;
  background-color: #14212d;
  border-left: 4px solid #0f9fb4;
} 
#navbarCollapse {
  background-color: #1d2f3e;
  border: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 44px;
  width: 100%;
  z-index: 1;
}
.collapse.in ul.nav.navbar-nav {
  display: flex;
  float: left;
  flex-direction: column;
}
.collapse.in ul.nav.navbar-nav li a{
  color: white;
}
#menu li span.fa{
  color: inherit;
}
.sidebar section h4 {
  color: #ffffff;
  font-size: 18px;
}
.sidebar h4 {
  margin: 0 0 10px 0;
}
.search-box .search-icon {
  background-image: url(../images/search-icon.png);
  background-repeat: no-repeat;
  background-position: 10px 12px;
  width: 40px;
}
.tagcloud a {
    background-color: #152737;
    border-left: 5px solid;
    color: #6b7e90;
    display: inline-block;
    margin: 2px 2px 4px;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
}
.tagcloud a:hover{
    color: #6b7e90;
    text-decoration: none;

}
.entry-info .col-xs-6{
    color: #a7a7a7;
    font-size: 12px;
    padding: 0;
}
.entry-date, .entry-standard {
    color: #8e8e8e;
    font-size: 12px;
    text-transform: capitalize;
    
}
label{
  margin-bottom: 5px;
}
.post_content.post-with-Img .social-icons{
  margin: 0 25px !important;
  margin-top: 2rem !important;
}
.pull-left{
  float: unset;
}
.entry-info.col-xs-12{
  display: flex;
  flex-wrap: wrap;
}
.entry-date.pull-left:nth-child(2){
margin-left: 10px;
}
.entry-date, .entry-standard{
  margin-bottom: 5px;
}
.rotate180 {
  transform: rotate(180deg);
}
 #masonry .entry-standard.entry-style.col-xs-6{
  width:100%;
  }
  /* .post-with-Img .post-image-container a img{
    height: 186px;
  } */
  .post .filetr-ondetail .select-list .group-list{
    padding: 0 1rem;
  }
  .post-container .topbar .search-block {
    display: none;
}
.post-container .topbar #searchToggle {
  display: none;
}
.post-container #masonry .parts {
  display: flex;
  margin-top: 5px;
  align-items: baseline;
  flex-wrap: wrap;
}
.post-container #masonry .parts label,
.post-container #masonry .parts a {
  color: #a7a7a7;
  font-size: 13px;
  font-weight: 500;
}
.post-container #masonry .parts a {
  margin-left: 3px;
  margin-right: 3px;
}
.post-container #masonry .parts a:hover,
.post-container #masonry .parts a:focus {
    color: #2a6496;
    text-decoration: underline;
}
.post-container #masonry .version {
  display: flex;
  margin-top: 5px;
  gap: 5px;
  flex-wrap: wrap;
}
.post-container #masonry .version a {
  color: black;
  font-size: 16px;
}
.post-container #masonry .version a:hover{
  text-decoration: underline;
}

.nav-item.menu-li-soc .fa-facebook:hover,
.nav-item.menu-li-soc .fa-x-twitter:hover,
.nav-item.menu-li-soc .fa-linkedin:hover{
  color: #5778ae;
  opacity: 0.75;
}
.social-icons .comment-cnt a:hover{
  color: #2a6496;
  text-decoration: underline;
}
.post {
  animation: spreadFromTopLeft 0.6s ease-in-out both;
  animation-delay: calc(var(--animation-order) * 0.3s);
}
footer{
  animation: spreadFromTopLeft 0.8s ease-in-out both;
  /* Add a slight delay to each card */
  animation-delay: calc(var(--animation-order) * 0.8s);
}
.nav-list {
  display: none; /* Hidden by default */
}

.nav-list.open {
  display: block; /* Show when open */
}
/* header .navbar-default .navbar-nav > .current-menu-item > a, header .navbar-default .navbar-nav > .current-menu-item > a:hover, header .navbar-default .navbar-nav > .current-menu-item > a:focus, header .navbar-default .navbar-nav>li>a:hover, header .navbar-default .navbar-nav>li>a:focus {
  background-color: transparent;
  color: #64ebff;
} */
ul.nav-list.desktop-nav li:focus a,ul.nav-list.desktop-nav li:hover a
{
  background-color: transparent;
  color: #64ebff;
}
ul.nav-list.desktop-nav li a:hover,
ul.nav-list.desktop-nav li a:focus
{
  color: #64ebff;
  background-color: transparent;
}
.alignright {
  margin-left: 15px;
  float: right;
}
@media screen and (max-width: 1024px) {
  #masonry .entry-standard.entry-style.col-xs-6 {
    width: 50%;
}
  /* .post-container #masonry .parts label{
    margin-bottom: 0 !important;
  } */
  .post_content {
    max-height: 380px;
}
.post p.post-description{
    height: unset;
  } 
.post-with-Img p.post-description {
    height: 51px;
}

  .post-no-Img p.post-description{
    max-height: 235px;
  }
  .post_content {
    max-height: 380px;
    height: unset;
  }
.post-with-Img p.post-description p {
    margin-top: 0 !important
}
  .blog-page .post-container .mobile-Blogger{
    margin-top: 101px;
}
  .post-container .main-category-page {
    flex-direction: column;
  }
  .post-container .main-category-page .pager {
    text-align: end;
  }
  .post-container form .filetr-ondetail{
    display: unset;
    padding-left: 0;
  }
  .post-container form .filetr-ondetail .selectgrp{
    width: fit-content;
  }
  .post-container form .filetr-ondetail div:first-child{
    margin-bottom: 1.2rem;
  }
  .post-container div.pager,
  .post-container .main-category-page form {
    margin-top: unset !important;
  }
  .post-container  form {
    padding-top: 30px !important;
  }
  .post-container div.pager {
    padding-top: 15px;
  }
  .post-container form {
    padding-left: 35px;
  }
  .topbar .breadcrumb {
    padding: 15px !important;
  }
  .topbar {
    display: block;
    height: 35px;
}
.topbar ul.category li a {
    padding: 8px 0;
}

.topbar ul.category li a.glyphicon.glyphicon-search {
  Font-size: 14px;
  margin-left: 15px;
}
.post-container .topbar #searchToggle {
  display:block;
}
.post-container .search-block form#searchform{
  padding:8px 15px;
}
.post-container .search-block form#searchform .search-box input {
  width: 100%;
}
  .post-container div.pager{
    margin-top: unset !important;
  }
 
  .mobile-Blogger .ceo-text {
    text-align: left;
    }
     
    .mobile-Blogger .mobile-BloggerPost {
    margin-top: 5px;
    }
  .fixed {
    margin-left: 0;
    padding-left: 0;
  }
  .post-container #masonry .row {
    justify-content: center;
    gap: 0;
}
.post-container {
    margin-bottom: 0;
}
.breadcrumb li {
    font-size: 12px;
}

  header .navbar-brand span:first-child, header .navbar-brand:hover span:first-child {
    font-size: 13px;
}
header .navbar{
  justify-content:unset;
  height: 55px;
}
header .navbar-brand .txtcolor {
  font-size: 13px;
  margin-left: 10px;
}
.main-container{
    margin-left: 0;
}
.navbar-header{
    order: 2;
}
button.toggle-btn {
    order: 1;
}
/*homepage css start*/
.topbar.filter .pull-right.social-icons {
  display: none;
}
.pager button {
  padding: 1px 7px;
  margin-right: 3px;
}
.post-container .pager {
  margin: 0 15px !important;
}
.post-container button.next {
  padding: 1px 5px;
  margin-right: 0;
}
.post-container button.prev {
  padding: 1px 5px;
}
header .nav-list.mobile-nav {
  background-color: #1d2f3e;
  border: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 55px;
  width: 100%;
  z-index: 1;
  display: block;
  padding: 10px;
  
    
}
header ul.nav-list.mobile-nav li {
  padding-top: 15px;
}
header ul.nav-list.mobile-nav .category-list li {
  padding-top: 0px;
}
 
header ul.nav-list.mobile-nav .category-list .category-name .fa-angle-right,
header ul.nav-list.mobile-nav .category-list .category-name .fa-angle-down {
  font-size: 18px;
  margin-right: 10px;
}
 
header ul.nav-list.mobile-nav .category-list .category-name a {
padding-left: 14px !important;
}
header ul.nav-list.mobile-nav li a {
  font-size: 14px;
}
.nav-item.category a{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

header .nav-list.mobile-nav .nav-item.category {

padding-bottom: 8px;

}

 
header .nav-list.mobile-nav .nav-item.category {
  display: flex;
  justify-content: space-between;
}
header .nav-list.mobile-nav .nav-item.category i {
  color: white;
  font-size: 20px;
  margin-right: 15px;
}

}
@media (max-width: 1366px) {
  .content {
      min-height: 850px;
  }
}
@media screen and (min-width: 375px) and (max-width: 450px)  {

  .post-container form {
    padding-left: 2.5rem;
  }

 }

@media screen and (min-width: 412px) and (max-width: 430px)  {

  .post-container form {
    padding-left: 3.8rem;
  }

 }
 



@media screen and (min-width: 1024px) {
  /* .post-container{
    width: 990px;
  } */
  .post-container div.pager{
    margin-top: 110px !important;
  }

  .navbar-header {
      float: left;   
  }
  ul.nav-list.desktop-nav{
      /* width: 35%; */
      text-align: right;
      
  }
}

.read-more a:hover, .read-more button:hover {
  cursor: pointer;
}
.topbar ul.social-icons li{
margin-right:0.5rem;
}
.topbar .social-icons .fa{
  border-radius: 12px;
  font-size: 15px;
  margin: 16px 0;
  padding: 4px 2px;
  text-decoration: none;
  text-align: center;
  vertical-align: bottom;
  height: 22px;
  width: 22px;
}
.fa-facebook-f {
  border: 1px solid #5778ae;
  color: #5778ae;
}

.fa-twitter, .fa-x-twitter {
  border: 1px solid #68c3f7;
  color: #68c3f7;
}

.fa-linkedin-in {
  border: 1px solid #60a0da;
  color: #60a0da;
}

.unlike-button .white-icon{
color:#fff;
}
/* mobile arun image  */
.mobile-Blogger{
  display: flex;
}
.ceo-text {
  text-align: left;    
}
.mobile-BloggerName {
  font-size: 15px;
}
.mobile-BloggerPost {
  font-size: 12px;
}
.post-container .pager {
  margin: 0px !important;
  position: relative !important;
}
ol.comment-list, ol.comment-list > li > ol {
  list-style: none outside none;
  margin-top: 0;
  padding: 0;
}

ol.comment-list li {
  list-style: none outside none;
  margin-bottom: 30px;
  float: left;
  position: relative;
  width: 100%;
}

.comment article, .comment-author.col-md-1 {
  padding: 0;
}
.comment-area .total-comments {
  font-size: 30px;
  margin-top: unset;
}
.comment-area a.hide-commentbox {
  color: #989898;
  display: inline-block;
  /* margin: 25px 20px; */
  margin-left: 2rem;
  text-transform: capitalize;
}
.comment-area a.hide-commentbox span.fa {
  margin-left: 3px;
}
.comment-metadata {
  margin-bottom: 1rem;
}
.comment-metadata span {
  color: #a0a0a0;
  font-size: 12px;
}

.comment-reply-link {
  color: #0f9fb4;
  font-size: 12px;
}
#breadcrumbs li a:hover{
  text-decoration:underline;
  }
   
  #breadcrumbs li a{
  text-transform: capitalize;
  }
  .topbar .breadcrumb li {
    text-transform: capitalize;
  }
