footer {
    color: #999999;
    font-size: 12px;
    height: 42px;
    padding: 15px;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #ebf0f1;
}