.category-list {
  list-style: none;
  padding: 0;
}
 
body, h1, h2, h3, h4, h5, h6 {
  font-family: "Segoe UI", Arial, sans-serif;
}
 
.category-name {
  cursor: pointer;
  display: flex;
  align-items: center;
}
 
.expand-icon {
  margin-left: auto; /* Push the icon to the right */
}
 
.subcategory-list {
  list-style: none;
  padding: 0;
  margin: 0;
 
}
.cyburl, .cyburl:hover {
  color: #66798b;
  font-size: 14px;
}
.subcategory {
  margin-top: 5px;
}
 
.category-name.expanded .fa-angle-right:before {
  content: "\f107"; /* Rotate down icon */
}
 
.ctc a:first-child {
  font-size: xx-large;
}
 
.category-list li span a {
  font-weight: 500;
}
 
.fa-angle-right:before {
  content: "\f105"; /* Rotate right icon */
}
 
.sidebar {
  /* background-color: #1d2f3e; */
  position: fixed;
  width: 290px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  z-index: 400;
}
 
.ceo-text {
  background-color: #409b8f;
  padding: 10px 15px;
  position: relative;
  text-align: center;
  width: 100%;
  color: white;
}
 
#menu {
  margin: 0 -20px;
  padding: 0;
}
 
#menu li {
  list-style: none;
}
 
.accordionMenu .fa-angle-right,.accordionMenu .fa-angle-down , #menu2 .fa-angle-right, .category-nav > a .fa-plus {
  float: right;
  font-size: 15px;
  margin-right: 30px;
}
 
.category-name {
  display: flex !important;
}
 
#menu > li.active:hover > span a {  
    border-left: 4px solid transparent;
}
#menu li .subcategory-list li:hover {
  background-color: #14212d;
  color: #0f9fb4;
  font-size: 12px;
  text-decoration: none;
}
 
#search, .input-group-btn .search-icon {
  background-color: #637789;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 10px;
  height: 38px;
}
 
.input-group {
  position: relative;
  display: flex;
  border-collapse: separate;
}
 
#menu li .subcategory-list li {
  font-size: 12px;
  color: #80a2be;
  text-transform: uppercase;
  display: block;
  padding: 0px 0 0px 18px;
}
 
.sidebar::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
  scrollbar-width: thin;
}
 
.sidebar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  scrollbar-width: thin;
}
 
.sidebar section h4 {
  color: #ffffff;
  font-size: 18px;
}
 
.sidebar h4 {
  margin: 0 0 10px 0;
}
 
.search-box .search-icon {
  background-image: url(../images/search-icon.png);
  background-repeat: no-repeat;
  background-position: 10px 12px;
  width: 40px !important;
}
.sidebar #search {
  width: 100%;
}
 
#menu li a {
  color: #ababab;
  display: block;
  padding: 5px 0 5px 18px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;
  border-left: 4px solid transparent ;
}
 
/* Apply styles to expanded category and active subcategory */
#menu > li.active > span{
  color: #0f9fb4;
  background-color: #14212d;
  border-left: 4px solid #0f9fb4;
}
#menu > li.active > span a, #menu > li.active > span span i,#menu > li > span:hover span i{
  color: #0f9fb4;
}
#menu li.subcategory a {
  color: #80a2be;
  font-size: 12px;
  text-decoration: none;
}
#menu li.subcategory a:hover{
color: #0f9fb4;
}
#menu > li .subcategory-list li.active  {
 
  background-color: #14212d;
  /* border-left: 4px solid #0f9fb4; */
}
#menu > li .subcategory-list li.active  a{
  color: #0f9fb4;
 
  /* border-left: 4px solid #0f9fb4; */
}
.post-container .pager {
  margin: 0 20px !important;
  position: relative !important;
}
 .tagcloud .ctc a:first-child{
  font-size:22pt;
 }
 .tagcloud .ctc a:nth-child(2),.tagcloud .ctc a:nth-child(3),.tagcloud .ctc a:nth-child(4){
  font-size:10.33pt;
 }
 .tagcloud .ctc a:nth-child(5),.tagcloud .ctc a:nth-child(6),.tagcloud .ctc a:nth-child(7),.tagcloud .ctc a:nth-child(8){
  font-size:9.16pt;
 }
 .tagcloud .ctc a{
  font-size:8pt;
 }
 
@media screen and (max-width: 1024px) {
  .post-container .pager {
    margin: 0 15px !important;
  }
}
 


  /* --------------------------------- */
/* Sidebar wrapper styles */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 290px; /* Adjust as needed */
  height: 100vh;
  overflow: hidden; /* Prevent outer scrollbar */
  background-color: #1d2f3e; /* Background color */
}
 
/* Sidebar styles */
.sidebar {
 
  scrollbar-width: none; /* Hide default scrollbar in Firefox */
}
 
/* Hide scrollbar initially */
.sidebar::-webkit-scrollbar {
  width: 5px;
  opacity: 0; /* Hide scrollbar by default */
  transition: opacity 0.3s ease;
}
 
/* Scrollbar thumb */
.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  transition: background 0.3s ease;
}
 
/* Scrollbar thumb on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}
 
/* Show scrollbar on hover */
.sidebar:hover::-webkit-scrollbar {
  opacity: 1; /* Show scrollbar */
}
 
/* Optional: Light background track when hovered */
.sidebar:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 5px; /* Width of the scrollbar */
  height: 100%;
  background: rgba(255, 255, 255, 0.1); /* Light scrollbar track color */
  border-radius: 10px;
  pointer-events: none; /* Allow interaction with scrollbar */
  transition: opacity 0.3s ease;
}
 
 
 
 
 
 
 
/* Sidebar styles */
.sidebar {
 
  scrollbar-width: none; /* Hide default scrollbar in Firefox */
}
 
/* Hide scrollbar initially */
.sidebar::-webkit-scrollbar {
  width: 5px;
  opacity: 0; /* Hide scrollbar by default */
  transition: opacity 0.3s ease;
  background: transparent; /* Make the track transparent */
}
 
/* Scrollbar thumb */
.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  transition: background 0.3s ease;
 
  min-height: 50px; /* Set a minimum height */
  height: 50px; /* Set a fixed height or percentage */
}
 
/* Scrollbar thumb on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}
 
/* Show scrollbar on hover */
.sidebar:hover::-webkit-scrollbar {
  opacity: 0; /* Show scrollbar */
}
 
/* Optional: Light background track when hovered */
.sidebar:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: 8px; /* This value aligns with the scrollbar width */
  width: 5px; /* Width of the scrollbar */
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Light scrollbar track color */
  border-radius: 10px;
  pointer-events: none; /* Allow interaction with scrollbar */
  transition: opacity 0.3s ease;
}
 